<template>
  <div>
    <p class="clearfix mb-0">
      <!-- <span class="float-md-left d-block d-md-inline-block mt-25" style="margin-right: 20px;">
        <b-link class="ml-25" href="https://socio.co.th/term-of-use" target="_blank"
          >เงื่อนไขการให้บริการ</b-link
        >
      </span>
      <span class="float-md-left d-block d-md-inline-block mt-25" style="margin-right: 38%;">
        <b-link class="ml-25" href="https://socio.co.th/privacy-statement/" target="_blank"
          >นโยบายความเป็นส่วนตัว</b-link
        >
        </span> -->
      <span class="float-md-left d-block d-md-inline-block mt-25 ml-25" >
        COPYRIGHT © {{ new Date().getFullYear() }}
        <b-link class="ml-25" href="https://www.socio.co.th" target="_blank"
          >Socio</b-link
        >
        <span class="d-none d-sm-inline-block">, All rights Reserved</span>
      </span>

      <!-- <span class="float-md-right d-none d-md-block"
        >Hand-crafted &amp; Made with
        <feather-icon
          icon="HeartIcon"
          size="21"
          class="text-danger stroke-current"
        />
      </span> -->
    </p>
    <!-- ไอคอนด้านล่าง -->
    <!-- <vue-facebook-chat :app-id="1330192291100112" :page-id="1531111423769199"></vue-facebook-chat> -->
    <!-- EAAS5zYbZBddABAA0OGGBJIPjAtQFLm8hcaJy5ZAtIfEVJpdz6CnruJoM8tB6C2hc4Yx1xzFKSHslBSVN2qJj7S7OHzhP9ZADRQZBjWZAo0ah7weAZANTUipcMHAXFsBoW24VySUdn8KaQmtxRjnAw7VfzpijDRTydqFF1EyFwxqchZBYZBzGX04Y3AialDEBNNSHpKSO8W3GzwZDZD -->
    <!-- <div class="btn-scroll-to-top" :class="{ show: 1000 > 250 }">
      <a href="line://ti/p/@jwr7944i" target="_blank">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="btn-icon"
        >
    
          <feather-icon icon="MessageCircleIcon" size="36" />
        </b-button>
      </a>
    </div> -->
  </div>
</template>

<script>
import { BLink, BButton } from "bootstrap-vue";
import { useWindowScroll } from "@vueuse/core";
import Ripple from "vue-ripple-directive";
import api from "@/api";
export default {
  directives: {
    Ripple,
  },
  components: {
    BLink,
    BButton,
  },
  setup() {
    const { y } = useWindowScroll();

    const scrollToTop = () => {
      const rootEle = document.documentElement;
      rootEle.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    return { y, scrollToTop };
  },
  mounted() {
    if(localStorage.getItem("store_id")){

      const params = {
        store_id: localStorage.getItem("store_id"),
      };
      api
      .post("check_term_of_use_stroe", params, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("response.data :>> ", response.data);
        if (response.data == 401) {
          // Term-Of-Uuse
          this.$router.push({ name: "term_of_use" });
        }
      })
      .catch((error) => {
        console.log("error.data :>> ", error.data);
      });
    }
  
  },
};
</script>
<style lang="scss" scoped>
.btn-scroll-to-top {
  position: fixed;
  bottom: 3%;
  right: 30px;
  z-index: 99;

  opacity: 0;
  // transform: translateY(100px);
  transition: all 0.5s ease;

  &.show {
    opacity: 1;
    // transform: translateY(0)
  }
}
</style>
  
  