export default [
  // {
  //   title: "Dashboard",
  //   route: "dashboard",
  //   icon: "CreditCardIcon",
  // },
  // {
  //   title: 'Guest Wifi',
  //   icon: 'HardDriveIcon',
  //   children: [
  //     {
  //       title: 'Splash page',
  //       icon: 'SendIcon',
  //       route: 'SplashPage',
  //     },
  //     {
  //       title: 'Wifi Network',
  //       children: [{
  //         title: 'Device',
  //         icon: 'HardDriveIcon',
  //         route: 'device',
  //       }, {
  //         title: 'SSID',
  //         icon: 'SendIcon',
  //         route: 'Bandwidth_Session',
  //       },
  //       ]

  //     },
  //   ],
  // },
  {
    title: "หน้าหลัก",
    icon: "HomeIcon",
    route: "reports_users",
  },
  {
    title: "การ์ดเมสเสจ แคมเปญ ",
    icon: "HomeIcon",
    route: "splash",
  },

  {
    title: "ล็อคอิน แคมเปญ ",
    icon: "TargetIcon",
    route: "web-quickPosts",
    // children: [
    //   {
    //     title: "Wi-Fi",
    //     children: [
    //       {
    //         title: "ล็อคอิน แคมเปญ ",
    //         route: "web-quickPosts",
    //       },
    //       {
    //         title: "การ์ดเมสเสจ แคมเปญ ",
    //         route: "splash",
    //       },
          
    //     ],
    //   },
      // {
      //   title: "LINE แคมเปญ",
      //   route: "PushMessage",
      // },


      // {
      //   title: "บรอดแคสต์",
      //   route: "BroadcastMessage",
      // },
    // ],
  },

  {
    title: "หน้าจอแบรนด์เพจ",
    icon: "TvIcon",
    route: "BrandPages",
   
  },
  // {
  //   header: "_____________________________",
  // },

  {
    title: "เมสเสจประเภทต่างๆ",
    icon: "MessageSquareIcon",
    route: "MessageCard",
    // children: [
    //   {
    //     title: "การ์ดเมสเสจ",
    //     route: "MessageCard",
    //   },
    // ],
  },
  // {
  //   title: "เครื่องมือ",
  //   icon: "ToolIcon",
  //   children: [
  //     {
  //       title: "Soon",
  //       route: "",
  //     },
  //   ],
  // },
  // {
  //   header: "_____________________________",
  // },
  {
    title: "report",
    icon: "ActivityIcon",
    route: "reports_connections",
    
  },
  {
    title: "ข้อมูลผู้เข้ารับบริการ",
    icon: "MessageCircleIcon",
    route: "register_users_line",
    // children: [
    // {
    //   title: "ผู้ใช้งานทั้งหมด",
    //   route: "register_users",
    // },
    //   {
    //     title: "ผู้ใช้งาน LINE",
    //     route: "register_users_line",
    //   },
    // ],
  },
  // {
  //   header: "_____________________________",
  // },
  // {
  //   title: 'เครื่องมือ',
  //   icon: 'GridIcon',
  //   children: [
  //     {
  //       title: 'คูปอง',
  //       route: ''
  //     },
  //     {
  //       title: 'เกมส์',
  //       route: '',
  //     },
  //   ],
  // },
  // {
  //   title: "การจัดการข้อมูล",
  //   icon: "DatabaseIcon",
  //   children: [
  //     // {
  //     //   title: 'การสร้างกลุ่มเป้าหมาย',
  //     //   route: 'TargetAudience'
  //     // },
  //     {
  //       title: "การสร้าง TAG",
  //       route: "taglist",
  //     },
  //   ],
  // },
  {
    title: "อุปกรณ์",
    icon: "HardDriveIcon",
    route: "device",
    // children: [
    //   {
    //     title: "WiFi Router",
    //     route: "device",
    //   },
    // ],
  },
  {
    title: "ตั้งค่า",
    icon: "SettingsIcon",
    children: [
      // {
      //   title: "ข้อมูลร้าน",
      //   route: "store",
      // },
      {
        title: "สาขา",
        route: "store-detail",
      },
      // {
      //   title: "หน้า Wi-Fi ล็อคอิน",
      //   route: "BrandPages",
      // },
      {
        title: "ผู้ใช้งานระบบ",
        route: "store-add-user",
      },
      // {
      //   title: "การเชื่อมต่อ",
      //   route: "connectOA",
      // },

      // {
      //   title: "เงื่อนไขการให้บริการ",
      //   route: "terms_of_service",
      // },
    ],
  },
  // {
  //   title: "แพ็จเกจ&การใช้งาน",
  //   icon: "PackageIcon",
  //   children: [
  //     {
  //       title: "ประวัติการชำระเงิน",
  //       icon: "ClipboardIcon",
  //       route: "payment_history",
  //     },
  //     // {
  //     //   title: 'เรียกเก็บเงิน',
  //     //   icon: 'ClipboardIcon',
  //     //   route: 'payment'
  //     // },
  //     // {
  //     //   title: "วิธีชำระเงิน & ชำระเงิน",
  //     //   icon: "ClipboardIcon",
  //     //   route: "payment_method",
  //     // },
  //     {
  //       title: "เปลี่ยนแพ็กเกจ",
  //       icon: "ClipboardIcon",
  //       route: "sales-Package",
  //     },
  //     {
  //       title: "วิธีชำระเงิน",
  //       icon: "ClipboardIcon",
  //       route: "payment_method",
  //     },

  //     {
  //       title: "ข้อมูลใบกำกับภาษี",
  //       icon: "ClipboardIcon",
  //       route: "tax_invoice",
  //     },
  //   ],
  // },
  // {
  //   title: "ติดต่อเรา",
  //   icon: "TwitchIcon",
  //   route: "contect",
  // },
  // {
  //   title: "รายการสินค้า",
  //   icon: "ArchiveIcon",
  //   route: "sales_order",
  // },
  // {
  //   title: "ออกจากระบบ",
  //   route: "logout",
  //   icon: "ShareIcon",
  // },
];
